import React, { useState } from "react"
import { Link } from "gatsby"
import { FiAlignJustify } from "react-icons/fi"
import logo from "../assets/images/logo2.png"
import LanguageSwitcher from './LanguageSwitcher'

const NavbarEng = () => {

  const [show, setShow] = useState(false)
  return (
    <nav className="navbar">
      <div className="nav-center">
        <div className="nav-header">
          <Link to="/en/">
            <img className="logoNav" src={logo} alt="Apsiha logo"/>
          </Link>
          <button className="nav-btn" onClick={() => setShow(!show)}>
           <FiAlignJustify />
          </button>
        </div>
        <div className={show ? "nav-links show-links" : "nav-links"}>
          <Link
            to="/en/"
            className="nav-link"
            activeClassName="active-link"
            onClick={() => setShow(false)}
          >
            Home
          </Link>
          <div className="dropdown-menu drop-link hidden">
            <input type="checkbox" id="menu" />
            <label htmlFor="menu">Services</label>
            <div className="menu-content">
                <ul>
                  <li>
                    <Link
                      to="/en/Services/psychotherapy"
                      className="nav-link"
                      activeClassName="active-link"
                      onClick={() => setShow(false)}
                    >
                      Psychotherapy
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/en/Services/expat_counselling"
                      className="nav-link"
                      activeClassName="active-link"
                      onClick={() => setShow(false)}
                    >
                      Expat Counselling
                    </Link>
                  </li>
                  <li>
                    <Link
                        to="/en/Services/employee_wellbeing"
                        className="nav-link"
                        activeClassName="active-link"
                        onClick={() => setShow(false)}
                      >
                        Employee Wellbeing
                      </Link>
                    </li>
                </ul>
            </div>
          </div>
          <Link
            to="/en/Services"
            className="nav-link hidden-lg"
            activeClassName="active-link"
            onClick={() => setShow(false)}
          >
            Services
          </Link>
          <Link
            to="/en/blog"
            className="nav-link"
            activeClassName="active-link"
            onClick={() => setShow(false)}
          >
            Blog
          </Link>
          <Link
            to="/en/Media"
            className="nav-link"
            activeClassName="active-link"
            onClick={() => setShow(false)}
          >
            Media
          </Link> 
          <Link
            to="/en/About"
            className="nav-link"
            activeClassName="active-link"
            onClick={() => setShow(false)}
          >
            About
          </Link>
          <LanguageSwitcher />
          <div className="contact-link">
            <Link to="/en/Contact" className="btn-contact" onClick={() => setShow(false)}>
              Contact
            </Link>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default NavbarEng
