import React, { useState } from "react"
import LayoutEng from "../../components/LayoutEng"
import SEO from "../../components/SEO"


const Media = ({data}) => {
  const [show, setShow] = useState(false)

  return (
    <LayoutEng>
      <SEO title="Media" description="Here you can find video clips as well as links to articles and blogs of our psychotherapists" />
      <main className="page media-page">
      <div className="center">
      <h1><strong className="pageNaslov">Apsiha </strong>in the media</h1>
      <p>Here you can find video clips as well as links to articles and blogs of our psychotherapists</p>
      </div>
      <div className="container"> 
          <div className={show ? "media-links show-as" : "media-links"}>
                <div className="dropdown-menu media-link">
                  <input type="checkbox" style={{ opacity: 0, position:'absolute', left:'-9999px'}} id="media1" />
                  <label htmlFor="media1">YouTube</label>
                  <div className="media-content">
                      <ul>
                          <li>
                            <div className="center">
                            <h4 className="media-link">#withnava | Expat Mental Health and Wellbeing with Ivana Mrgan - Aug 21, 2024</h4>
                            <iframe className="jubito" title="expat_mental_health" src="https://www.youtube.com/embed/rNQVqsnpdIU" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                          </li>
                          <li>
                            <div className="center">
                            <h4 className="media-link">Psychotherapist around the Globe - Croatia - Jul 17, 2024</h4>
                            <iframe className="jubito" title="psychotherapist_around_the_globe_croatia" src="https://www.youtube.com/embed/BgpWNq5ByHg" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                          </li>
                          <li>
                            <div className="center">
                            <h4 className="media-link">What a sober thinks, a drunk says - Nov 2, 2023</h4>
                            <iframe className="jubito" title="psychotherapist_around_the_globe_croatia" src="https://www.youtube.com/embed/XCWmmovmaXc?si=NnQxzA91NyQ5Fnwx" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                          </li>
                          <li>
                            <div className="center">
                            <h4 className="media-link">Life and work far from the homeland - Sep 11, 2022</h4>
                            <iframe className="jubito" title="rad_van_domovine" src="https://www.youtube.com/embed/AS1JzUQZfj0?si=Y0p2xBiipM4CAATv" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                          </li>
                          <li>
                            <div className="center">
                            <h4 className="media-link">At our home: Panic attacks - May 28, 2019</h4>
                            <iframe className="jubito" title="panicni_napadaji" src="https://www.youtube.com/embed/LaOtyVOQjvg" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                          </li>
                          <li>
                            <div className="center">
                            <h4 className="media-link">Scientific circles: About perfectionism - Jul 7, 2017</h4>
                            <iframe className="jubito" title="perfekcionizam" src="https://www.youtube.com/embed/IhxTwhTWuhY" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                          </li>
                      </ul>
                  </div>
                </div>
                <div className="dropdown-menu media-link">
                  <input type="checkbox" style={{ opacity: 0, position:'absolute', left:'-9999px'}} id="media2" />
                  <label htmlFor="media2">Net.hr</label>
                  <div className="media-content">
                      <ul>
                        <li>
                          <a
                            href="https://net.hr/magazin/zdravlje/depresija-mucna-bolest-koja-moze-biti-znak-jos-tezeg-oboljenja-kako-se-u-nasim-krajevima-zamaskirala-u-jedan-svakodnevniji-problem-2bb93378-b1c6-11eb-93d6-0242ac13003e"
                            target="_blank" 
                            rel="noreferrer"
                            className="media-link"
                            onClick={() => setShow(false)}
                          >
                            Depresija - Mučna bolest koja može biti znak težeg oboljenja
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://net.hr/magazin/koliko-medu-nama-ima-narcisa-savrseno-izvana-iznutra-suplje-a-drustvene-mreze-to-sve-nagraduju-3c39ff38-b1c7-11eb-848a-0242ac130043"
                            target="_blank" 
                            rel="noreferrer"
                            className="media-link"
                            onClick={() => setShow(false)}
                          >
                            Koliko među nama ima - narcisa?
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://net.hr/magazin/zdravlje/ovi-ljudi-zive-u-teskom-i-zastrasujucem-svijetu-a-obitelj-ih-se-nerijetko-srami-i-skriva-ih-ovo-je-prica-o-shizofreniji-morate-je-znati-3b02780c-b1c7-11eb-8242-0242ac130043"
                            target="_blank" 
                            rel="noreferrer"
                            className="media-link"
                            onClick={() => setShow(false)}
                          >
                            Priča o shizofreniji koju morate znati
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://net.hr/magazin/poremecaj-od-kojeg-pati-velik-broj-hrvata-epizode-ptsp-a-mogu-trajati-i-po-nekoliko-sati-evo-kako-do-njega-dolazi-i-koji-su-simptomi-3b92a996-b1c6-11eb-8168-0242ac13004d"
                            target="_blank" 
                            rel="noreferrer"
                            className="media-link"
                            onClick={() => setShow(false)}
                          >
                            PTSP - Poremećaj od kojeg pati veliki broj Hrvata
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://net.hr/magazin/sto-kad-vas-strah-ili-tjeskoba-potpuno-zarobe-evo-sto-sve-o-trebate-znati-o-anksioznim-poremecajima-20bdc4d4-b1c6-11eb-a923-0242ac130033"
                            target="_blank" 
                            rel="noreferrer"
                            className="media-link"
                            onClick={() => setShow(false)}
                          >
                            Što kad vas strah i tjeskoba potpuno 'zarobe'?
                          </a>
                        </li>
                      </ul>
                  </div>
                </div>
                <div className="dropdown-menu media-link">
                  <input type="checkbox" style={{ opacity: 0, position:'absolute', left:'-9999px'}} id="media3" />
                  <label htmlFor="media3">She.hr</label>
                  <div className="media-content">
                      <ul>
                        <li>
                          <a
                            href="https://she.hr/influenceri-i-njihov-utjecaj-na-mentalno-zdravlje/"
                            target="_blank" 
                            rel="noreferrer"
                            className="media-link"
                            onClick={() => setShow(false)}
                          >
                          Influenceri i njihov utjecaj na mentalno zdravlje
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://she.hr/kako-sam-potrazio-la-pomoc-psihologa/"
                            target="_blank" 
                            rel="noreferrer"
                            className="media-link"
                            onClick={() => setShow(false)}
                          >
                          Kako sam potražio/la pomoć psihologa
                          </a>
                        </li>
                        <li>
                          <a
                            href="http://she.hr/novi-pocetci-teski-i-oni-tezi/"
                            target="_blank" 
                            rel="noreferrer"
                            className="media-link"
                            onClick={() => setShow(false)}
                          >
                          Novi početci – teški i oni teži
                          </a>
                        </li>
                        <li>
                          <a
                            href="http://she.hr/o-zenama-i-mamicama/"
                            target="_blank" 
                            rel="noreferrer"
                            className="media-link"
                            onClick={() => setShow(false)}
                          >
                          O ženama i mamicama?
                          </a>
                        </li>
                        <li>
                          <a
                            href="http://she.hr/obojimo-svakodnevicu/"
                            target="_blank" 
                            rel="noreferrer"
                            className="media-link"
                            onClick={() => setShow(false)}
                          >
                          Obojimo svakodnevicu
                          </a>
                        </li>
                        <li>
                          <a
                            href="http://she.hr/psiholoska-fleksibilnost-i-kako-ju-njegovati/"
                            target="_blank" 
                            rel="noreferrer"
                            className="media-link"
                            onClick={() => setShow(false)}
                          >
                          Psihološka fleksibilnost i kako ju njegovati
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://she.hr/razgovor-seks-i-ljubav-kvalitetna-komunikacija-je-vazna-za-izgradnju-uspjesne-stabilne-i-dugorocne-veze/"
                            target="_blank" 
                            rel="noreferrer"
                            className="media-link"
                            onClick={() => setShow(false)}
                          >
                          Kvalitetna komunikacija je važna za izgradnju uspješne, stabilne i dugoročne veze
                          </a>
                        </li>
                        <li>
                          <a
                            href="http://she.hr/stres-nas-svagdasnji/"
                            target="_blank" 
                            rel="noreferrer"
                            className="media-link"
                            onClick={() => setShow(false)}
                          >
                          Stres naš svagdašnji
                          </a>
                        </li>
                        <li>
                          <a
                            href="http://she.hr/sto-je-savjetovanje-online-i-je-li-za-vas/"
                            target="_blank" 
                            rel="noreferrer"
                            className="media-link"
                            onClick={() => setShow(false)}
                          >
                          Što je savjetovanje online i je li za vas?
                          </a>
                        </li>
                        <li>
                          <a
                            href="http://she.hr/sto-je-socijalna-anksioznost-i-kako-je-prevladati/"
                            target="_blank" 
                            rel="noreferrer"
                            className="media-link"
                            onClick={() => setShow(false)}
                          >
                          Što je socijalna anksioznost i kako je prevladati
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://she.hr/zasto-se-muskarci-toliko-boje-lijecnika/"
                            target="_blank" 
                            rel="noreferrer"
                            className="media-link"
                            onClick={() => setShow(false)}
                          >
                          Zašto se muškarci toliko boje liječnika?
                          </a>
                        </li>
                      </ul>
                  </div>
                </div>
                <div className="dropdown-menu media-link">
                  <input type="checkbox" style={{ opacity: 0, position:'absolute', left:'-9999px'}} id="media5" />
                  <label htmlFor="media5">Vecernji.hr</label>
                  <div className="media-content">
                      <ul>
                        <li>
                          <a
                            href="https://www.vecernji.hr/lifestyle/u-blagdansko-vrijeme-raste-broj-depresivnih-i-tuznih-osoba-kako-se-nositi-sa-sezonskom-depresijom-1135035"
                            target="_blank" 
                            rel="noreferrer"
                            className="media-link"
                            onClick={() => setShow(false)}
                          >
                          U blagdansko vrijeme smo žalosniji: Kako se nositi sa sezonskom depresijom?
                          </a>
                        </li>
                      </ul>
                  </div>
                </div>
              </div>
              </div>
      </main>
    </LayoutEng>
  )
}

export default Media
